import { Navigate, Outlet, matchPath, useLocation } from "react-router-dom";

import {
  getAccessTokenPayloadSync,
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

import { flatMap } from "lodash";

import { ModalAcceptTerms } from "@components/modals/modal-accept-terms";
import { usePermissionsFlags } from "@flash-hros/auth-helper";
import { ErrorBoundary } from "@utils/error-boundary";
import ROUTES from "@utils/route-names";

export const checkUnleashPermission = (flagName: string) => {
  const flags = usePermissionsFlags.getState().flags;

  return !!flags?.some((_flag: any) => _flag.name === flagName);
};

export const PermissionGuard = ({ children }: { children?: JSX.Element }) => {
  const location = useLocation();
  const accessToken = getAccessTokenPayloadSync();

  const { selectedCompany } = useSelectedCompany();

  const allPermissions = usePermissions();

  const companyPermissions = allPermissions?.companies?.find(
    (company) => company.id === selectedCompany.id,
  );

  const permissions = companyPermissions?.permissions || [];

  const slugRoutes = [
    {
      slug: "people_recruitment_list_job_openings",
      routes: [ROUTES.JOB_OPENINGS.path, ROUTES.MY_JOB_OPENINGS.path],
    },
    {
      slug: "people_recruitment_manage_job_openings",
      routes: [
        ROUTES.JOB_OPENINGS.path,
        ROUTES.JOB_APPLICATION.path,
        ROUTES.JOB_APPLICATION.PIPELINES.path,
        ROUTES.JOB_APPLICATION.EMAIL_TEMPLATE.path,
        ROUTES.JOB_OPENINGS.CLOSE.path,
        ROUTES.JOB_OPENINGS.CREATE.path,
        ROUTES.SETTINGS.path,
      ],
    },
  ];

  const routesBySlug = slugRoutes.filter((route) =>
    permissions.some(
      (permission) => permission === "*" || permission === route.slug,
    ),
  );

  const routes = flatMap(routesBySlug, "routes");

  const isRouteAllowed = routes.some((c) =>
    matchPath({ path: c, end: true }, location.pathname),
  );

  const hasUnleashPermission = checkUnleashPermission(
    "FLASH_OS_PEOPLE_RECRUITMENT",
  );

  if (!isRouteAllowed || !hasUnleashPermission) {
    ErrorBoundary.captureException(
      new Error(
        `Access Error ${JSON.stringify({
          employeeId: accessToken?.employeeId || "-",
          routeAllowed: !!isRouteAllowed,
          unleashAllowed: !!hasUnleashPermission,
          companyId: selectedCompany.id || "-",
          admin: !!permissions?.find((p) => p === "*"),
          list: !!permissions?.find(
            (p) => p === "people_recruitment_list_job_openings",
          ),
          manage: !!permissions?.find(
            (p) => p === "people_recruitment_manage_job_openings",
          ),
        })}`,
      ),
    );
  }

  if (!isRouteAllowed) {
    return <Navigate to={"/home"} />;
  }

  return (
    <>
      {children ? children : <Outlet />} <ModalAcceptTerms />
    </>
  );
};
