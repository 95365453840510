import { trpc } from "@api/client";
import {
  getAccessToken,
  getFromLS,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import type { QueryClient } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { bffUrlMap, processEnv } from "@utils/env";
import type { ReactNode } from "react";

type TrpcClientCustomProps = {
  selectedCompany: ReturnType<typeof useSelectedCompany>["selectedCompany"];
};

export const trpcClient = ({ selectedCompany }: TrpcClientCustomProps) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        url: bffUrlMap[processEnv.BUILD_ENV],
        async headers() {
          const accessToken = await getAccessToken();
          const xRequestId = Math.random().toString(36).substring(2, 15);
          return {
            authorization: accessToken,
            "x-flash-auth": `Bearer ${accessToken}`,
            "x-request-id": xRequestId,
            "x-selected-company": selectedCompany?.id,
            "x-accept-terms": getFromLS("accept-terms"),
          };
        },
      }),
    ],
  });

export function TrpcProvider({
  children,
  client,
  selectedCompany,
}: {
  children: ReactNode;
  client: QueryClient;
  selectedCompany: ReturnType<typeof useSelectedCompany>["selectedCompany"];
}) {
  return (
    <trpc.Provider
      client={trpcClient({ selectedCompany })}
      queryClient={client}
    >
      {children}
    </trpc.Provider>
  );
}
