import { Spinner, ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { Suspense, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { Box } from "@components/layouts/box";

import {
  useRegisterRoutes,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { TrpcProvider } from "@providers/trpc.provider";

import routes from "@routes";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import ROUTES from "@utils/route-names";

const router = createBrowserRouter(routes);

export default function Root() {
  const { selectedCompany } = useSelectedCompany();

  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, [selectedCompany?.id]);

  useRegisterRoutes([
    {
      path: ROUTES.JOB_OPENINGS.path,
      hideHeader: false,
    },
    {
      path: ROUTES.MY_JOB_OPENINGS.path,
      hideHeader: false,
    },
    {
      path: ROUTES.JOB_OPENINGS.CREATE.path,
      hideHeader: true,
    },
    {
      path: ROUTES.JOB_OPENINGS.CLOSE.path,
      hideHeader: true,
    },
    {
      path: ROUTES.JOB_APPLICATION.path,
      hideHeader: false,
    },
    {
      path: ROUTES.JOB_APPLICATION.PIPELINES.path,
      hideHeader: false,
    },
    {
      path: ROUTES.JOB_APPLICATION.EMAIL_TEMPLATE.path,
      hideHeader: false,
    },
    {
      path: ROUTES.MY_JOB_APPLICATION.path,
      hideHeader: false,
    },
    {
      path: ROUTES.MY_JOB_APPLICATION.PIPELINES.path,
      hideHeader: false,
    },
    {
      path: ROUTES.MY_JOB_APPLICATION.EMAIL_TEMPLATE.path,
      hideHeader: false,
    },
    {
      path: ROUTES.SETTINGS.path,
      hideHeader: false,
    },
  ]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: 2,
        retryDelay: 3000,
      },
      mutations: {
        retry: false,
      },
    },
  });

  return (
    <TrpcProvider client={queryClient} selectedCompany={selectedCompany}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <Suspense
            fallback={
              <Box
                $width={100}
                alignItems="center"
                justifyContent="center"
                style={{ height: "100vh" }}
              >
                <Spinner size={48} variant="primary" />
              </Box>
            }
          >
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </Suspense>
        </ThemeProvider>
      </QueryClientProvider>
    </TrpcProvider>
  );
}
