import { Outlet } from "react-router-dom";

import { ModalMyAcceptTerms } from "@components/modals/modal-my-accept-terms";

export const PublicGuard = ({ children }: { children?: JSX.Element }) => {
  return (
    <>
      {children ? children : <Outlet />} <ModalMyAcceptTerms />
    </>
  );
};
