import { trpc } from "@api/client";
import { setInLS } from "@flash-tecnologia/hros-web-utility";
import { useEffect, useState } from "react";

export const useAcceptTerms = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: termsData, isFetching: isFetchingAcceptTerms } =
    trpc.company.employees.findTerms.useQuery();

  useEffect(() => {
    if (!termsData) return;

    setInLS({
      key: "accept-terms",
      value: termsData?.hasAgreed,
    });

    if (!termsData?.hasAgreed) {
      setIsModalOpen(true);
    }
  }, [termsData?.hasAgreed]);

  const { mutate: onAcceptTerm, isLoading: isLoadingAcceptTerms } =
    trpc.company.employees.agreeTerms.useMutation({
      onSuccess() {
        setInLS({
          key: "accept-terms",
          value: true,
        });
        setIsModalOpen(false);
      },
    });

  return {
    isModalOpen,
    setIsModalOpen,
    isFetchingAcceptTerms,
    onAcceptTerm,
    isLoadingAcceptTerms,
    termsData,
  };
};
